import React from 'react';
import './styles.css';
import LeftImagesButton from "./buttons/leftButton";
import RightImagesButton from "./buttons/rightButton";

const ImagesNavigation = ({
  onPreviousClick,
  onNextClick,
}) => {
  return (
    <div className="images-navigation-wrapper">
        <div className="navigation">
          {
            <LeftImagesButton onClick={onPreviousClick} />
          }
          {
            <RightImagesButton onClick={onNextClick}  />
          }
        </div>
    </div>
  );
};

export default ImagesNavigation;
