import React from "react";
import "./styles.css";

const SelectActivityButton = ({ onClick, isSelected }) => {
  return (
    <div
      className={`button-activity-outer ${isSelected ? "pressed" : ""}`}
      onClick={onClick}
    >
      <div className={`button-activity-inner ${isSelected ? "pressed" : ""}`}>
        Select
      </div>
    </div>
  );
};

export default SelectActivityButton;