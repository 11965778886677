import React, {useEffect, useState} from 'react';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {setHotelId, updateOfferSelection} from '../../../../store/actions/offerActions';

import FlightOfferDetails from './details/FlightOffer';
import '../expandableItem/styles.css';
import DetailsNavigation from '../../../footer/detailsNavigation';
import HotelOfferDetails from "./details/HotelOffer";
import TransferOfferDetails from "./details/TransferOffer";
import HotelAmenitiesPage from "./details/HotelAmenitiesPage";
import {countFirstLevelChildren} from "../../../utils/dataProcessingFunctions";
import SelectionButton from "./buttons/selectionButton";

const DetailPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const {myListData} = location.state || {}; // Extract myListData from the location state

    const item = myListData ? myListData.find(item => item.id === Number(id)) : null;

    const dispatch = useDispatch();
    const currentOfferId = useSelector((state) => state.offer.currentOffer);
    const selectedOptionIndexFromRedux = useSelector((state) => {
        const offerDetails = state.offer.selectedOffers[currentOfferId] || {};
        return offerDetails[item.id] || 0;
    });

    const [selectedOptionIndex, setSelectedOptionIndex] = useState(selectedOptionIndexFromRedux);

    useEffect(() => {
        setSelectedOptionIndex(selectedOptionIndexFromRedux);
    }, [selectedOptionIndexFromRedux]);

    // Set the hotel ID in Redux when hotel details are available
    useEffect(() => {
        if (item && item.info1 && item.info1.details) {
            dispatch(setHotelId(currentOfferId, selectedOptionIndex + 1));
        }
    }, [item, selectedOptionIndex, dispatch, currentOfferId]);

    // Returns to the previous page
    const handleBackClick = () => {
        navigate(-1);
    };

    const handleOptionClick = (index) => {
        setSelectedOptionIndex(index);
        dispatch(updateOfferSelection(currentOfferId, item.id, index));
    };

    if (!item) {
        return <div>Item not found</div>;
    }

    const renderHotelDetails = (details, index) => {
        if (details) {
          const hotelDetails = details[index];
        return <HotelOfferDetails details={hotelDetails}/>;
        }
        else {
            return <div> Problem with hotel details. Please retry search.</div>
        }
    };

    const renderHotelAmenities = (details, index) => {
        const hotelDetails = details[index];
        return <HotelAmenitiesPage details={hotelDetails}/>;
    };

    const getClassName = (numberOfButtons, buttonOrderNumber) => {
        switch (numberOfButtons) {
            case 1:
                return buttonOrderNumber === 0 ? "single-button rounded-bottom-button" : "";
            case 2:
                return buttonOrderNumber === 0 ? "one-of-two left" : "one-of-two right";
            case 3:
                return buttonOrderNumber === 0 ? "left" :
                    buttonOrderNumber === 1 ? "central" : "right";
            default:
                return "";
        }
    };

    const renderOptions = (selectionBox) => {
        return Object.keys(selectionBox).map((key, index) => {
            const option = selectionBox[key];
            const isSelected = index === selectedOptionIndex;
            const buttonCount = countFirstLevelChildren(selectionBox);

            return (
                <SelectionButton
                    key={key}
                    price={option.price}
                    onClick={() => handleOptionClick(index)}
                    isSelected={isSelected}
                    className={getClassName(buttonCount, index)}
                />
            );
        });
    };

    const renderDetails = (details, index) => {
        if (item.id === 1 || item.id === 5) {
            const flightDetails = details[index];
            return <FlightOfferDetails details={flightDetails}/>;
        } else if (item.id === 2 || item.id === 4) {
            const detail = details[index];
            return <TransferOfferDetails details={detail}/>;
        }
        return <p>{item.info1}</p>;
    };

    return (
        <div className="details-wrapper">
            <div className="overlay-content">
                {(item.id === 1 || item.id === 5 || item.id === 2 || item.id === 4) ? (
                    <>
                        {renderDetails(item.info1.details, selectedOptionIndex)}
                        <div className="selection-options">{renderOptions(item.info1['selection-box'])}</div>
                    </>
                ) : (
                    <>
                        {renderHotelDetails(item.info1.details, selectedOptionIndex)}
                        <div className="selection-options">{renderOptions(item.info1['selection-box'])}</div>
                        {renderHotelAmenities(item.info1.details, selectedOptionIndex)}
                    </>
                )}
            </div>
            <DetailsNavigation onClick={handleBackClick}/>
        </div>
    );
};

export default DetailPage;
