import React, { useState } from "react";
import "./styles.css";

import {ReactComponent as RightButton} from "../../../images/icons/chevron-left.svg"

const RightMonthButton = ({ onClick }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleClick = () => {
    setIsPressed(true);
    setTimeout(() => {
      setIsPressed(false);
    }, 300);
    onClick();
  };

  return (
    <div
      className={`right-month-outer rounded-right-button ${isPressed ? "pressed" : ""}`}
      onClick={handleClick}
    >
      <div className={`right-month-inner rounded-right-button ${isPressed ? "translate" : ""}`}>
        <RightButton style={{ transform: 'rotate(180deg)'}}/>
      </div>
    </div>
  );
};

export default RightMonthButton;
