import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ExpandableItem from './expandableItem/expandableItem';
import LoadingSpinner from '../../utils/loadingSpinner/loadingSpinner';
import {useNavigate} from 'react-router-dom';
import GetRequest from "../../api/getRequest";
import {setTransferData, setTransferFetched} from "../../../store/actions/transferActions";
import RenderDestinationButtons from "./carouselFunctions";
import SearchAgainButton from "../../tripOrganisation/buttons/searchAgainButton";
import {
    countFirstLevelChildren,
    getNumberOfValidOffers,
    getRequestParamsFromCookies,
    processFlightToDestination,
    processAccommodationInfo,
    processFlightFromDestination,
    processTransferToAccommodation,
    processTransferFromAccommodation,
    isOfferValid
} from "../../utils/dataProcessingFunctions";

import './styles.css';
import '../../../commonStyles.css';
import {
    calculateVacationDays,
    getGrandTotal
} from "../../utils/calculationFunctions";

const NUMBER_OF_TRANSFER_POLL_ATTEMPTS = 5;
const TRANSFER_POLL_ATTEMPTS_DELAY = 3000;

function OffersCarousel({offers = [], isLoading}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const transferData = useSelector((state) => state.transfer.data); // Get transferData from Redux
    const selectedOffers = useSelector((state) => state.offer.selectedOffers);
    const currentOfferId = useSelector((state) => state.offer.currentOffer);
    const {transferFetched} = useSelector((state) => state.transfer);
    const currentRealOfferDetails = offers[currentOfferId] || null;

    // Step 1: Define loadingTransfers state
    const [loadingTransfers, setLoadingTransfers] = useState(true);

    useEffect(() => {
        if (offers.length === 0) {
            navigate('/');
        }
    }, [offers, navigate]);

    useEffect(() => {
        const pollTransfers = async () => {
            if (!transferFetched) {
                for (let i = 0; i < NUMBER_OF_TRANSFER_POLL_ATTEMPTS; i++) {
                    try {
                        const path = 'api/fetch_transfers/';
                        const transferData = await GetRequest(path)();
                        if (transferData && transferData.message.length > 0) {
                            dispatch(setTransferData(transferData)); // Store transfer data in Redux
                            dispatch(setTransferFetched(true)); // Indicate that transfer has been fetched
                            setLoadingTransfers(false); // Step 4: Set loadingTransfers to false after receiving data
                            break;
                        }
                        await new Promise(resolve => setTimeout(resolve, TRANSFER_POLL_ATTEMPTS_DELAY));
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                }
            } else {
                setLoadingTransfers(false); // If already fetched, set loadingTransfers to false
            }
        };

        if (offers.length > 0) {
            pollTransfers();
        }
    }, [offers, transferFetched, dispatch]);


    const createOffer = (id, selectionBoxOptions = {}, details = []) => ({
        id,
        info1: {
            "selection-box": selectionBoxOptions,
            details,
        }
    });

    const mockedOffers = offers.map((offer, index) => ({
        destination: `Destination ${index + 1}`,
        offers: [
            createOffer(1, {
                option1: {price: '', time: ''},
                option2: {price: '', time: ''},
                option3: {price: '', time: ''},
            }, [
                {segments: [], totalDuration: ''},
                {segments: [], totalDuration: ''},
                {segments: [], totalDuration: ''}
            ]),
            createOffer(2, {
                option1: {price: '', time: ''}
            }, [
                {
                    segments: [{
                        start: {dateTime: "", locationCode: ""},
                        end: {dateTime: ""},
                        transferType: "",
                        vehicle: {
                            code: "",
                            category: "",
                            duration: "",
                            seats: [{count: ""}],
                            baggages: [{count: "", size: ""}],
                            imageURL: ""
                        }
                    }]
                }
            ]),
            createOffer(3),
            createOffer(4, {
                option1: {price: '', time: ''}
            }, [
                {
                    segments: [{
                        start: {dateTime: "", locationCode: ""},
                        end: {dateTime: ""},
                        vehicle: {
                            code: "",
                            category: "",
                            duration: "",
                            seats: [{count: ""}],
                            baggages: [{count: "", size: ""}],
                            imageURL: ""
                        }
                    }]
                }
            ]),
            createOffer(5, {
                option1: {price: '', time: ''},
                option2: {price: '', time: ''},
                option3: {price: '', time: ''}
            }, [
                {segments: [], totalDuration: ''},
                {segments: [], totalDuration: ''},
                {segments: [], totalDuration: ''}
            ])
        ]
    }));

    const getOfferDetails = (offers, offerId, index) => {
        try {
            const offerDetails = offers[offerId]?.offers?.[index];
            if (!offerDetails) {
                throw new Error("No details");
            }
            return offerDetails;
        } catch (error) {
            console.error(`No details for offerId: ${offerId} at index: ${index}`);
            return null;
        }
    };

    let currentOfferDetailsFlightTo = getOfferDetails(mockedOffers, currentOfferId, 0);
    let currentOfferDetailsTransferTo = getOfferDetails(mockedOffers, currentOfferId, 1);
    let currentOfferDetailsHotel = getOfferDetails(mockedOffers, currentOfferId, 2);
    let currentOfferDetailsTransferFrom = getOfferDetails(mockedOffers, currentOfferId, 3);
    let currentOfferDetailsFlightBack = getOfferDetails(mockedOffers, currentOfferId, 4);

    if (currentRealOfferDetails == null) {
        navigate('/');
        return null;
    }

    let validOffer = isOfferValid(currentRealOfferDetails);
    if (validOffer) {
        if (currentRealOfferDetails?.offers?.data?.[currentOfferId]?.[1]
            && Array.isArray(currentRealOfferDetails.offers.data[currentOfferId][1])) {
            currentRealOfferDetails.offers.data[currentOfferId][1].forEach((hotelInfo, index) => {
                if (hotelInfo) {
                    processAccommodationInfo(hotelInfo, index + 1, currentOfferDetailsHotel);
                }
            });
        }

        processFlightToDestination(currentRealOfferDetails.offers.data, currentOfferDetailsFlightTo);
        processFlightFromDestination(currentRealOfferDetails.offers.data, currentOfferDetailsFlightBack);

        if (transferData) {
            processTransferToAccommodation(transferData, currentOfferDetailsTransferTo, currentOfferId);
            processTransferFromAccommodation(transferData, currentOfferDetailsTransferFrom, currentOfferId);
        }
    }

    if (currentRealOfferDetails) {
        mockedOffers[currentOfferId].offers[0] = currentOfferDetailsFlightTo;
        mockedOffers[currentOfferId].offers[1] = currentOfferDetailsTransferTo;
        mockedOffers[currentOfferId].offers[2] = currentOfferDetailsHotel;
        mockedOffers[currentOfferId].offers[3] = currentOfferDetailsTransferFrom;
        mockedOffers[currentOfferId].offers[4] = currentOfferDetailsFlightBack;
        mockedOffers[currentOfferId].destination = currentRealOfferDetails.offers.city;
    } else {
        navigate('/');
        return null;
    }

    console.log("mocked offer debug", mockedOffers)

    let travelOfferTotal;
    let totalPeople;
    let cookieData;
    let totalNumberOfOffers = countFirstLevelChildren(offers)
    let numberOfValidOffers;
    if (validOffer) {
        travelOfferTotal = getGrandTotal(selectedOffers, currentOfferId, mockedOffers);
        cookieData = getRequestParamsFromCookies();
        totalPeople = cookieData ? cookieData.adults + cookieData.children : '';
        numberOfValidOffers = getNumberOfValidOffers(offers);
        console.log("ovo mora daima", numberOfValidOffers)
    }


    // Check offers before rendering
    const renderButtons = offers.length > 0 ? RenderDestinationButtons(offers, totalNumberOfOffers) : null;

    return (
        <div className="offers-container">
            {isLoading ? (
                <LoadingSpinner/>
            ) : (
                mockedOffers.length > 0 && currentOfferDetailsFlightTo ? (
                    <>
                        <div className="offer-item">
                            <div className="offer-header">
                                <div className="offers-title-container">
                                    <div className="offers-title-container-inner">
                                        <div className="offers-monitor-outer">
                                            <div className="offers-monitor highlight deep-effect">
                                                {cookieData?.departureDate && cookieData?.returnDate ? (
                                                    <p className="vacation-range">
                                                        {calculateVacationDays(cookieData.departureDate, cookieData.returnDate)} days
                                                    </p>
                                                ) : (
                                                    <p className="vacation-range">Offer expired</p>
                                                )}
                                                <div className="grand-total-wrapper">
                                                    <p className="grand-total-icons">For {totalPeople} Travelers</p>
                                                    <p className="grand-total">Total: {travelOfferTotal}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {renderButtons}
                            </div>
                            <div className="offers-list-wrapper">
                                {validOffer ? (
                                    <ExpandableItem
                                        myListData={mockedOffers[currentOfferId].offers}
                                        onOfferSelect={(offer) => console.log('Offer selected:', offer)}
                                        isLoadingTransfers={loadingTransfers}
                                    />
                                ) : (
                                    <>
                                        <p className="no-hotels-message">
                                            There are no hotels found for this destination. Please check other offer(s)
                                            or search with different criteria.
                                        </p>
                                        <SearchAgainButton/>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    <p>No offers available</p>
                )
            )}
        </div>
    );
}

export default OffersCarousel;
