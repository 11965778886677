import React, { useState } from "react";
import "./styles.css";
import {ReactComponent as LeftImageIcon} from "../../../images/icons/chevron-left.svg";

const RightImagesButton = ({ onClick }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleClick = () => {
    setIsPressed(true);
    setTimeout(() => {
      onClick(); // Switch to the next page after the animation
      setIsPressed(false); // Reset the pressed state
    }, 300); // 300 ms delay to match the animation duration
  };

  return (
    <div
      className={`right-images-outer ${isPressed ? "pressed" : ""}`}
      onClick={handleClick}
    >
      <div className={`right-images-inner ${isPressed ? "translate" : ""}`}>
        <LeftImageIcon style={{ transform: 'rotate(180deg)' }}  />
      </div>
    </div>
  );
};

export default RightImagesButton;