import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import UserDataEntryStep from './steps/initialDataEntry/userDataEntryStep';
import BudgetSmartphoneStep from './steps/setBudget/budgetSmartphoneStep';
import CitiesPickStep from './steps/chooseCities/cityPickStep';
import TravelTypeStep from './steps/setActivityTag/typeOfTravelStep';
import PostRequest from '../api/postRequest';
import GetRequest from '../api/getRequest';
import Cookies from 'js-cookie';
import LoadingSpinner from "../utils/loadingSpinner/loadingSpinner";
import {useDispatch} from "react-redux";
import {resetOffers} from "../../store/actions/offerActions";
import {resetTransferFetched} from "../../store/actions/transferActions";
import SearchAgainButton from "./buttons/searchAgainButton";

const SearchForm = () => {
    const [idealDestination, setIdealDestination] = useState(true);
    const [rangeNotSelectedError, setRangeNotSelectedError] = useState(true);
    const [formData, setFormData] = useState({
        departureLocation: '',
        destination: '',
        adults: 0,
        children: 0,
    });
    const [dateRange, setDateRange] = useState({
        departureDate: null,
        returnDate: null,
    });
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [fetchError, setFetchError] = useState(null);

    const {stepId} = useParams();
    const [step, setStep] = useState(parseInt(stepId) || 1);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (stepId) setStep(parseInt(stepId));
    }, [stepId]);

    const RANGE_NOT_SELECTED_ERROR = 'Please select both start and end dates.';
    const calendarPlaceholder = 'select dates';

    // Fetch offers automatically at Step 5
    useEffect(() => {
        if (step === 5) {
            dispatch(resetOffers());
            dispatch(resetTransferFetched());
            const fetchData = async () => {
                const fetchedOffers = await fetchOffers();
                if (fetchedOffers) {
                    console.log('Offers fetched successfully.');
                    navigate('/offers', {state: {offers: fetchedOffers, isLoading: false}});
                } else {
                    return (
                        <>
                            <div className="error-detail">
                                Failed to get any offers. Please try again with different search criteria.
                            </div>
                            <SearchAgainButton/>
                        </>
                    );
                }
            };
            fetchData();
        }
    }, [step, navigate, dispatch]);

    // Fetch offers from API
    const fetchOffers = async () => {
        setIsLoading(true);
        try {
            const getOffers = GetRequest('api/get_offers/');
            const data = await getOffers();
            if (data.message) {
                const formattedOffers = Object.keys(data.message).map((offerId) => ({
                    offerId,
                    offers: data.message[offerId]
                }));
                return formattedOffers;
            } else {
                throw new Error('Failed to load offers');
            }
        } catch (error) {
            console.error("Error fetching offers:", error);
            setFetchError(error.message);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const resetStep2State = () => {
        setFormData({departureLocation: '', destination: '', adults: 0, children: 0});
        setDateRange({departureDate: null, returnDate: null});
        setRangeNotSelectedError(false);
    };

    // Handle step transition, store `activityTag` in cookie at Step 1
    const nextStep = async (tagId = null) => {
        console.log("Received tagId in nextStep:", tagId);

        if (step === 1 && tagId) {
            // Set `activityTag` in a cookie for retrieval in Step 2
            Cookies.set('activityTag', tagId, {path: '/'});
        } else if (step === 2) {
            if (!(dateRange.departureDate && dateRange.returnDate && dateRange.departureDate !== dateRange.returnDate)) {
                console.error(RANGE_NOT_SELECTED_ERROR);
                setRangeNotSelectedError(true);
                return;
            }
            // Retrieve `activityTag` from cookie in Step 2
            const activityTag = Cookies.get('activityTag');
            await sendApiInitialData(activityTag);
        }

        // if (step === 3) setSelectedCities(tagId);

        goToStep(step + 1);
    };

    const goToStep = (stepNumber) => {
        setStep(stepNumber);
        navigate(`/search/${stepNumber}`);
    };

    const prevStep = () => {
        const newStep = step > 1 ? step - 1 : 1;
        if (step === 2) resetStep2State();
        setStep(newStep);
        goToStep(newStep);
    };

    const formatDateToLocal = (date) => {
        if (!date) return null;
        const offset = date.getTimezoneOffset();
        return new Date(date.getTime() - offset * 60 * 1000).toISOString().split('T')[0];
    };

    const sendApiInitialData = async (activityTag) => {

        const requestParams = JSON.stringify({
            ...formData,
            activityTag: activityTag,
            departureDate: dateRange.departureDate ? formatDateToLocal(dateRange.departureDate) : null,
            returnDate: dateRange.returnDate ? formatDateToLocal(dateRange.returnDate) : null,
        });

        const requestData = {message: JSON.parse(requestParams)};
        try {
            const data = await PostRequest(requestData, 'api/start/', 'json');
            if (data) {
                Cookies.set('request_params', requestParams, {
                    sameSite: window.location.protocol === 'https:' ? 'None' : 'Lax',
                    secure: window.location.protocol === 'https:',
                    path: '/'
                });
            } else {
                console.error('No task_id found in response data.');
            }
        } catch (error) {
            console.error('Fetch failed:', error);
        }
    };

    const formatDateRange = (range) => {
        const {departureDate, returnDate} = range;
        const formatDate = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };

        if (departureDate && returnDate) {
            return `${formatDate(departureDate)} - ${formatDate(returnDate)}`;
        } else {
            return calendarPlaceholder;
        }
    };

    const formattedDateRange = formatDateRange(dateRange);

    const handleChange = (name) => (newValue) => {
        setFormData(prevFormData => ({...prevFormData, [name]: newValue}));
    };

    const handleInputChange = (name) => (e) => {
        setFormData(prevFormData => ({...prevFormData, [name]: e.target.value}));
    };

    const handleRangeSelect = (range) => {
        setDateRange(range);
    };

    const handleSelectedTags = (tags) => {
        setSelectedTags(tags);
    };

    const toggleIdealDestination = () => {
        setIdealDestination(prev => !prev);
    };

    // In SearchForm component
    const resetFormAndNavigate = () => {
        // Reset form data and other relevant state variables
        setFormData({
            departureLocation: '',
            destination: '',
            adults: 0,
            children: 0,
        });
        setDateRange({
            departureDate: null,
            returnDate: null,
        });
        setSelectedTags([]);
        setSelectedCities([]);
        setFetchError(null);
        setIsLoading(false);
        // Navigate to the initial step
        navigate('/search/1');
    };


    return (
        <>
            {step === 1 && (
                <TravelTypeStep
                    nextStep={nextStep}
                    handleInputChange={handleInputChange}
                    values={formData}
                    setTravelType={(travelType) =>
                        handleInputChange('travel_type')({target: {value: travelType}})
                    }
                />
            )}
            {step === 2 && (
                <UserDataEntryStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleChange={handleChange}
                    handleInputChange={handleInputChange}
                    values={formData}
                    toggleIdealDestination={toggleIdealDestination}
                    idealDestination={idealDestination}
                    onRangeSelect={handleRangeSelect}
                    formattedDateRange={formattedDateRange}
                    setRangeNotSelectedError={setRangeNotSelectedError}
                    selectedTags={selectedTags}
                />
            )}
            {step === 3 && (
                <CitiesPickStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                />
            )}
            {step === 4 && (
                <BudgetSmartphoneStep
                    nextStep={nextStep}
                    prevStep={prevStep}
                    handleInputChange={handleInputChange}
                    selectedCities={selectedCities}
                />
            )}
            {step === 5 && (
                <div>
                    {isLoading ? (
                        <LoadingSpinner/>
                    ) : fetchError ? (
                        <div>
                            <p>Error: {fetchError}</p>
                            <p>Please try again later.</p>
                            <SearchAgainButton onReset={resetFormAndNavigate}/>
                        </div>
                    ) : (
                        <p>Offers fetched successfully. Redirecting...</p>
                    )}
                </div>
            )}
        </>
    );
};

export default SearchForm;
