import React, { useState } from "react";
import "../styles.css";
import "../carousel/styles.css";
import {ReactComponent as MenuIcon} from "../../../images/icons/menu-svgrepo-com.svg"

const MenuButton = ({ onClick, style }) => {
  const [isPressed, setIsPressed] = useState(false);

  // const handleClick = () => {
  //   setIsPressed(true);
  //   setTimeout(() => {
  //     setIsPressed(false);
  //   }, 300);
  //   onClick();
  // };

  return (
    <div
      className={`button-outer header-button menu ${isPressed ? "pressed" : ""}`}
    >
      <div className={`button-inner menu ${isPressed ? "translate" : ""}`}>
       <MenuIcon style={style} />
      </div>
    </div>
  );
};

export default MenuButton;
