import React, {useEffect, useState} from "react";
import PostRequest from "../../../api/postRequest";
import LoadingSpinner from "../../../utils/loadingSpinner/loadingSpinner";
import '../../../../commonStyles.css';
import '../styles.css';
import './styles.css';
import image1 from "../../../../images/sample1.jpeg";
import image2 from "../../../../images/sample2.jpeg";
import image3 from "../../../../images/sample3.jpeg";
import image4 from "../../../../images/sample4.jpeg";
import image5 from "../../../../images/sample5.jpeg";
import image6 from "../../../../images/sample6.jpeg";
import image7 from "../../../../images/barcelona1.jpeg";
import image8 from "../../../../images/barcelona2.jpeg";
import image9 from "../../../../images/rome1.jpg";
import image10 from "../../../../images/rome2.jpeg";
import ImagesCitiesSlider from "../../../utils/imagesSlider/ImagesCitiesSlider";
import GetRequest from "../../../api/getRequest";

const MAX_CITIES_DISPLAYED = 15;
const MAX_ATTEMPTS_TO_FETCH = 10;
const RETRY_DELAY = 3000;

const imageSets = [
    [image1, image2],
    [image3, image4],
    [image5, image6],
    [image7, image8],
    [image9, image10]
];

function CitiesPickStep({nextStep, prevStep}) {
    const [cities, setCities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndices, setCurrentIndices] = useState({});

    useEffect(() => {
        const fetchCitiesWithRetry = async (attempt = 0) => {
            if (attempt >= MAX_ATTEMPTS_TO_FETCH) {
                console.error("Failed to fetch cities after multiple attempts");
                return;
            }

            console.log("Try to send get dest", attempt);
            const getData = GetRequest('api/get_destinations/');
            const data = await getData();

            if (data.cities && data.cities.length > 0) {
                setCities(data.cities.slice(0, MAX_CITIES_DISPLAYED));
                setIsLoading(false);
            } else {
                setTimeout(() => {
                    fetchCitiesWithRetry(attempt + 1);
                }, RETRY_DELAY);
            }
        };

        fetchCitiesWithRetry(); // Initial call with attempt 0
    }, []);


    const handleNextStep = async (city) => {

        try {
            await PostRequest({city}, 'api/set_cities/', 'json');
            console.log('Cities set successfully');
            console.log(city);
            nextStep(city);
        } catch (error) {
            console.error("Error setting cities: ", error);
        }
    };

    return (
        <>
            <div className="form-inline-wrapper">
                {isLoading ? (
                    <LoadingSpinner/>
                ) : (
                    <>
                        <h2 className='page-title'>Select a destination</h2>
                        <p>You can later change it or come back</p>

                        <div className="cities-container">
                            {cities.map((city, index) => {
                                const imageSet = imageSets[index % imageSets.length];
                                const currentIndex = currentIndices[index] || 0;

                                return (
                                    <div key={index} className="city-slider-container bottom-shadow">
                                        <ImagesCitiesSlider
                                            preloadedImages={imageSet}
                                            currentIndex={currentIndex}
                                            altAttribute={`Images of ${city}`}
                                            onClick={() => handleNextStep(city)} // Pass onClick handler here
                                            cityName={city}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                    </>
                )}
            </div>
        </>
    );
}

export default CitiesPickStep;

