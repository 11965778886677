import React, { useEffect } from 'react';
import './styles.css'; // Import the styles for fullscreen view
import {ReactComponent as LeftImageIcon} from "../../../../../images/icons/chevron-left-screen-light.svg";
const FullScreenImageViewer = ({ images, currentIndex, onClose, onNext, onPrev }) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            } else if (event.key === 'ArrowRight') {
                onNext();
            } else if (event.key === 'ArrowLeft') {
                onPrev();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [onClose, onNext, onPrev]);

    return (
        <div className="fullscreen-overlay" onClick={onClose}>
            <div className="fullscreen-content" onClick={(e) => e.stopPropagation()}>
                <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} className="fullscreen-image" />
                <div className="image-counter">{currentIndex + 1} / {images.length}</div>
                <button className="nav-button prev-button" onClick={onPrev}><LeftImageIcon/></button>
                <button className="nav-button next-button" onClick={onNext}><LeftImageIcon style={{ transform: 'rotate(180deg)'}}/></button>
                <button className="close-button" onClick={onClose}>X</button>
            </div>
        </div>
    );
};

export default FullScreenImageViewer;
