import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import '../../../commonStyles.css'

function LoadingSpinner() {

  return (
    <div className="spinner-container">
      <TailSpin
         type="TailSpin"
         color="#18788A"
         height={200}
         width={200}
         timeout={10000} //10 secs
      />
    </div>
  );
}

export default LoadingSpinner;
