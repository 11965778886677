import React from 'react';

import './styles.css';
import CheckoutButton from "../offersPage/buttons/checkoutButton";
import ProfileButton from "../offersPage/buttons/profileButton";
import MenuButton from "../offersPage/buttons/menuButton";
import SearchButton from "../offersPage/buttons/searchButton";
import FilterButton from "../offersPage/buttons/filterButton";

const OffersNavigation = ({
                              onClick
                          }) => {

    let sideLength = "27px";
    let sideLengthProfile = "30px";
    let sideLengthMagnifier = "25px";
    const style = {width: sideLength, height: sideLength}
    const styleProfile = {width: sideLengthProfile, height: sideLengthProfile}
    const styleMagnifier = {width: sideLengthMagnifier, height: sideLengthMagnifier}
    return (
        <div className="navigation-wrapper">
            <SearchButton style={styleMagnifier}></SearchButton>
            <MenuButton style={style}></MenuButton>
            <CheckoutButton onClick={onClick}/>
            <FilterButton style={style}></FilterButton>
            <ProfileButton style={styleProfile}></ProfileButton>
        </div>
    );
};

export default OffersNavigation;
