import React, {useState} from "react";
import "../styles.css";
import "../carousel/styles.css";

const DestinationButton = ({onClick, position, cityName, isSelected}) => {


    // const handleClick = () => {
    //     setIsPressed(true);
    //     setTimeout(() => {
    //         onClick(); // Switch to the next page after the animation
    //         setIsPressed(false); // Reset the pressed state
    //     }, 300); // 300 ms delay to match the animation duration
    // };

    let innerClass;
    const setInnerClass = (position) => {
        let innerClass;
        if (position !== '') {
            if (position === 'city-left') {
                innerClass = 'city-left-inner';
                return innerClass;
            } else if (position === 'city-right') {
                innerClass = 'city-right-inner';
                return innerClass;
            } else if (position === 'first-of-two-cities') {
                innerClass = 'inner-first-of-two-cities'
                return innerClass;
            } else if (position === 'second-of-two-cities') {
                innerClass = 'inner-second-of-two-cities'
                return innerClass;
            } else if (position === 'one-city') {
                innerClass = 'one-city-inner'
                return innerClass;
            } else {
                innerClass = '';
                return innerClass;
            }
        }
    }
    innerClass = setInnerClass(position);

    return (

        <div className={`button-city ${position} ${isSelected ? 'active' : ''} `}
             onClick={onClick}>
            <div className={`button-city-inner ${innerClass} ${isSelected ? 'pressed' : ''} `}>
                <p className="destination-name">{cityName}</p>
            </div>
        </div>
    );
};

export default DestinationButton;

