import React from 'react';
import '../styles.css';

import {ReactComponent as PlaneIconLanding} from '../../../../../images/icons/plane-landing.svg';
import {ReactComponent as PlaneIconTakeOff} from '../../../../../images/icons/plane-takeoff.svg';

const FlightOfferDetails = ({details}) => {

    const calculateLayoverTime = (arrivalTime, departureTime) => {
        const arrival = new Date(arrivalTime);
        const departure = new Date(departureTime);
        const difference = departure - arrival;

        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

        return `${hours}h ${minutes}m`;
    };

    const formatDuration = (duration) => {
        const matches = duration.match(/PT(\d+H)?(\d+M)?/);
        const hours = matches[1] ? matches[1].slice(0, -1) : '0';
        const minutes = matches[2] ? matches[2].slice(0, -1) : '0';
        return `${hours}h${minutes}m`;
    };

    return (
        <div className="offer-details">
            <h2 className="page-title">Arrival</h2>

            <div className="container-info flight-container">
                <div className="flight-info-inner">

                    <div className="flight-monitor highlight">
                        {details.segments.map((segment, index) => (
                            <React.Fragment key={index}>
                                <div className="flight-details-container">
                                    <div className="flight-details-departure">
                                        <span className="airport-text">{segment.departure['iataCode']}</span>
                                        <span
                                            className="departure-time"><PlaneIconTakeOff/> {segment.departure.at.slice(11, 16)}</span>
                                    </div>
                                    <div className="flight-duration-container">
                                        <p className="duration-text">&lt;- {formatDuration(segment.duration)} -&gt; </p>
                                    </div>
                                    <div className="flight-details-arrival">
                                        <span className="airport-text">{segment.arrival['iataCode']}</span>
                                        <span
                                            className="arrival-time"><PlaneIconLanding/> {segment.arrival.at.slice(11, 16)}</span>

                                    </div>

                                </div>

                                {/*<div className="time-gate">*/}
                                {/*    <div className="flight-check-in">*/}
                                {/*        <span className="detail-title">Flight Number</span>*/}
                                {/*        <span className="detail-information">{segment.flightNumber}</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="terminal-info">*/}
                                {/*        <span className="detail-title">Terminal</span>*/}
                                {/*        <span className="detail-information">{segment.departure.terminal}</span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {index < details.segments.length - 1 && (
                                    <div className="layover-info">
                                        <p
                                            className="layover-text"> {calculateLayoverTime(segment.arrival.at, details.segments[index + 1].departure.at)} Layover
                                            CITY ({segment.arrival['iataCode']}) </p>
                                    </div>
                                )}

                                <div className="baggage-info">
                                    {/*<div className="carrier-info">*/}
                                    {/*    <span className="detail-information">{segment.carrier}</span>*/}
                                    {/*</div>*/}
                                    {/*<div className="class-info">*/}
                                    {/*    <div className="detail-title">Ticket Class</div>*/}
                                    {/*    <span*/}
                                    {/*        className="detail-information">{segment.fareDetailsBySegment?.fareOption || 'N/A'}</span>*/}
                                    {/*</div>*/}
                                    <span className="left-item"> BAGGAGE </span>
                                    <span className="right-item"> {segment['baggage']}</span>
                                </div>
                            </React.Fragment>

                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlightOfferDetails;
