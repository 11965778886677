import React from "react";
import SkiingIcon from '../../../../images/tags/travel_type/retro/v2/skiing_tag.png';
import SummerVacationIcon from '../../../../images/tags/travel_type/retro/v2/SUMMER_VACATION_PIXELIZE.png';
import HikingIcon from '../../../../images/tags/travel_type/retro/hiking.png';
import UrbanExploration from '../../../../images/tags/travel_type/retro/v2/urban_exploration-bold-2.png';
import ShoppingIcon from '../../../../images/tags/travel_type/retro/shopping.png';
import YachtingIcon from '../../../../images/tags/travel_type/retro/yachting.png';

import Tag from "../../tag/tag";

function TravelTypeStep({ nextStep }) {
    const handleTagClick = (tagId) => {
        setTimeout(() => nextStep(tagId), 0);
    };

    const tags = [
        { id: 'skiing', icon: SkiingIcon, alt: 'Skiing' },
        { id: 'summer_vacation', icon: SummerVacationIcon, alt: 'Summer Vacation' },
        { id: 'hiking', icon: HikingIcon, alt: 'Hiking' },
        { id: 'urban_exploration', icon: UrbanExploration, alt: 'Urban Exploration' },
        { id: 'shopping', icon: ShoppingIcon, alt: 'Shopping' },
        { id: 'yachting', icon: YachtingIcon, alt: 'Yachting' },
    ];

    return (
        <div className="form-inline-wrapper">
            <h2 className="page-title">Do you have a preferred type of travel?</h2>
            <div className="tags-container">
                {tags.map(tag => (
                    <Tag
                        key={tag.id}
                        id={tag.id}
                        icon={tag.icon}
                        alt={tag.alt}
                        onClick={() => handleTagClick(tag.id)}  // Pass tag ID to next step function
                    />
                ))}
            </div>
        </div>
    );
}

export default TravelTypeStep;
