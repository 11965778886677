import React from 'react';

import './styles.css'; // Import your CSS file here

import PlusButton from "../tripOrganisation/buttons/plusButton";
import MinusButton from "../tripOrganisation/buttons/minusButton";
import ChildrenButton from "../tripOrganisation/buttons/childrenButton";

function CounterChildren({ value, onChange }) {

  const increment = () => {
    onChange(value + 1);
  };

  const decrement = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  return (
    <div className="counter">
      <div className="controls">
        <MinusButton onClick={decrement}/>
        <ChildrenButton/>
        <PlusButton onClick={increment}/>
      </div>
      <div className="adult-counter-info rounded-right-button">
        <span className="adult-counter-info-inner rounded-right-button">
            <div className={`adult-counter-value ${value > 0 ? 'highlight' : ''}`}> {value}</div>
        </span>
      </div>
    </div>
  );
}

export default CounterChildren;
