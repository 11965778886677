import React, { useState } from "react";
import "./styles.css";

const NextButton = ({ onClick }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleClick = () => {
    setIsPressed(true);
    setTimeout(() => {
      setIsPressed(false);
    }, 300);
    onClick();
  };

  return (
    <div
      className={`button-next-outer rounded-right-button ${isPressed ? "pressed" : ""}`}
      onClick={handleClick}
    >
      <div className={`button-next-inner rounded-right-button ${isPressed ? "translate" : ""}`}>
        Next
      </div>
    </div>
  );
};

export default NextButton;
