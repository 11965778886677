import React from 'react';
import '../styles.css';
import StarsDisplay from "../../expandableItem/utils/ratingStars";

const HotelOfferDetails = ({details}) => {

    if (!details) {
        return <div className="error-message">No details available</div>;
    }

    return (
            <div className="offer-details">
                {/*<h2 className="page-title">Accommodation</h2>*/}
                <div className="container-info">
                    <div className="container-info-inner">
                        <div className="hotel-info-inner">
                            <div className="hotel-monitor highlight deep-effect">
                                {details.segments.map((detail, index) => (
                                    <React.Fragment key={index}>
                                        <div className="hotel-details-container">
                                            <div className="option-stars">
                                                <StarsDisplay
                                                    numberOfStars={detail.stars}/>
                                            </div>
                                            <div className="hotel-name">
                                                {detail.name || 'N/A'}
                                            </div>

                                            {/*<div>Free Cancellation Until: {detail.freeCancellationUntil}</div>*/}
                                            <div className="accommodation-details">
                                                <div className="check-in-details">
                                                    <div className="check-in-title">CHECK-IN</div>
                                                    <div className="check-in-date"> {detail.checkInDate}</div>
                                                    <div className="check-in-time"> {detail.checkInTime}</div>
                                                </div>
                                                <div className="room-details">
                                                    <div className="room-title">ROOM</div>
                                                    <div className="room-info"> {detail.roomInfo}</div>
                                                </div>
                                                <div className="check-out-details">
                                                    <div className="check-out-title">CHECK-OUT</div>
                                                    <div className="check-out-date"> {detail.checkOutDate}</div>
                                                    <div className="check-out-time"> {detail.checkOutTime}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
        ;
};

export default HotelOfferDetails;
