import React from 'react';
import { useLocation } from 'react-router-dom';
import OffersCarousel from './carousel/carousel';
import OffersNavigation from "../footer/offersNavigation";

const OffersPage = () => {
    const location = useLocation();
    const {offers = [], isLoading = false } = location.state || {};

    return (
        <div>
            <OffersCarousel offers={offers} isLoading={isLoading} />
            <OffersNavigation/>
        </div>
    );
};

export default OffersPage;
