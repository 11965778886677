// ImagesSlider.js

import React from 'react';
import { useSwipeable } from 'react-swipeable'; // Import useSwipeable for swipe handling
import ImagesNavigation from "../../footer/imagesNavigation";

const ImagesSlider = ({ preloadedImages, currentIndex, altAttribute, handleImageClick, handleNext, handlePrev }) => {
    // Define swipe handlers
    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        preventDefaultTouchmoveEvent: true, // Prevent scrolling on swipe
        trackMouse: true, // Optional: Enables swipe with mouse for testing
    });

    return (
        <div {...swipeHandlers} className="images-container-outer">
            <div className="images-container">
                <div className="images-container-inner">
                    {preloadedImages[currentIndex] ? (
                        <img
                            src={preloadedImages[currentIndex]}
                            alt={altAttribute}
                            className="image"
                            onClick={() => handleImageClick(currentIndex)}
                        />
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
                <div className="image-navigation">
                    <span>{Number(currentIndex) + 1}/{preloadedImages.length}</span>
                </div>
            </div>
            <div className="images-buttons-wrapper">
                <ImagesNavigation
                    onNextClick={handleNext}
                    onPreviousClick={handlePrev}
                />
            </div>
        </div>
    );
};

export default ImagesSlider;
