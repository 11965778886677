import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import '../styles.css';
import '../../../../../commonStyles.css';

import FullScreenImageViewer from './FullScreenImageViewer';
import ImagesNavigation from "../../../../footer/imagesNavigation";
import ImagesSlider from '../../../../utils/imagesSlider/ImagesSlider';
import {setImageIndex} from '../../../../../store/actions/offerActions';

const CACHE_EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour in milliseconds
const IMAGE_PROPERTIES = '1024x768';

const ImagesPage = ({details}) => {
    const dispatch = useDispatch();
    const currentOfferId = useSelector((state) => state.offer.currentOffer);
    const currentHotelId = useSelector((state) => state.offer.hotelIds[currentOfferId] || 1);
    const savedIndex = useSelector((state) => Number(state.offer.imageIndices[currentOfferId]?.[currentHotelId] || 0));

    const Images = details.images.map(url => url.replace('{size}', IMAGE_PROPERTIES));
    const totalImages = Images.length;

    const [preloadedImagesByHotel, setPreloadedImagesByHotel] = useState({});
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(savedIndex);

    const uniqueCacheKey = `imagesCache_${details.hotelId}_${Date.now()}`;

    useEffect(() => {
        const loadImage = (src, index) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = () => resolve({index, src});
                img.onerror = reject;
            });
        };

        const preloadImagesForHotel = async (hotelId) => {
            try {
                const cachedData = localStorage.getItem(`${uniqueCacheKey}_${hotelId}`);
                const now = Date.now();

                if (cachedData) {
                    const {images, timestamp} = JSON.parse(cachedData);
                    if (now - timestamp < CACHE_EXPIRATION_TIME) {
                        setPreloadedImagesByHotel(prevImages => ({
                            ...prevImages,
                            [hotelId]: images
                        }));
                        return;
                    } else {
                        localStorage.removeItem(`${uniqueCacheKey}_${hotelId}`);
                    }
                }

                const loadedImages = new Array(totalImages).fill(null);
                for (let i = 0; i < Images.length; i++) {
                    const {index, src} = await loadImage(Images[i], i);
                    loadedImages[index] = src;
                    setPreloadedImagesByHotel(prevImages => ({
                        ...prevImages,
                        [hotelId]: [...loadedImages]
                    }));
                }

                localStorage.setItem(`${uniqueCacheKey}_${hotelId}`, JSON.stringify({
                    images: loadedImages,
                    timestamp: now,
                }));

            } catch (error) {
                console.error('Error preloading images:', error);
            }
        };

        if (!preloadedImagesByHotel[currentHotelId]) {
            preloadImagesForHotel(currentHotelId);
        }
    }, [Images, currentHotelId, preloadedImagesByHotel, uniqueCacheKey]);

    useEffect(() => {
        setCurrentIndex(savedIndex);
    }, [savedIndex]);

    const handleNext = () => {
        if (!currentHotelId) return;
        const newIndex = (Number(currentIndex) + 1) % totalImages;
        setCurrentIndex(newIndex);
        dispatch(setImageIndex(currentOfferId, currentHotelId, newIndex));
    };

    const handlePrev = () => {
        if (!currentHotelId) return;
        const newIndex = (Number(currentIndex) - 1 + totalImages) % totalImages;
        setCurrentIndex(newIndex);
        dispatch(setImageIndex(currentOfferId, currentHotelId, newIndex));
    };

    const handleImageClick = (index) => {
        if (!currentHotelId) return;
        setCurrentIndex(index);
        setIsFullScreen(true);
        dispatch(setImageIndex(currentOfferId, currentHotelId, index));
    };

    const handleCloseFullScreen = () => {
        setIsFullScreen(false);
    };

    const preloadedImages = preloadedImagesByHotel[currentHotelId] || [];

    if (!details) {
        return <div className="error-message">No details available</div>;
    }

    return (
        <>
            <div className="images-wrapper-outer bottom-shadow">
                <div className="images-wrapper">
                    <ImagesSlider
                        preloadedImages={preloadedImages}
                        currentIndex={currentIndex}
                        altAttribute="Hotel Pictures"
                        handleImageClick={handleImageClick}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                    />
                </div>
            </div>

            {isFullScreen && (
                <FullScreenImageViewer
                    images={preloadedImages}
                    currentIndex={currentIndex}
                    onClose={handleCloseFullScreen}
                    onNext={handleNext}
                    onPrev={handlePrev}
                />
            )}
        </>
    );
};

export default ImagesPage;
