import React from "react";
import "./styles.css";

const YearButton = ({children}) => {
  return (
    <div className="button-year-outer bottom-shadow rounded-button">
      <div className="button-year-inner rounded-button">
          {children}
      </div>
    </div>
  );
};

export default YearButton;
