import {calculateDurationInMinutes} from "../../../../utils/calculationFunctions";
import React from 'react'; // Import React for JSX

const TransferInfo = ({transferDetails}) => {
    if (transferDetails.segments[0].transferType !== 0) {
        return <p className="monitor-text"> {transferDetails.segments[0].transferType} - {calculateDurationInMinutes(transferDetails.segments[0].start.dateTime, transferDetails.segments[0].end.dateTime)}</p>;
    } else {
        return <p className="monitor-text"> No available transfers</p>;
    }
}

export default TransferInfo;