import React, { useState } from "react";
import "../styles.css";
import "../carousel/styles.css";
import { ReactComponent as SearchIcon } from "../../../images/icons/magnifying-glass.svg";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const SearchButton = ({ onClick, style }) => {
  const [isPressed, setIsPressed] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Function to handle button click
  const handleClick = () => {
    setIsPressed(true);
    setTimeout(() => {
      setIsPressed(false);
      navigate('/'); // Navigate to the root
      if (onClick) onClick(); // Call onClick if provided
    }, 100);
  };

  return (
    <div
      className={`button-outer header-button search-again ${isPressed ? "pressed" : ""}`}
      onClick={handleClick} // Add onClick handler
    >
      <div className={`button-inner search-again ${isPressed ? "translate" : ""}`}>
        <SearchIcon style={style} />
      </div>
    </div>
  );
};

export default SearchButton;
