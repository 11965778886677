import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PlaneIconLanding from '../../../../images/icons/plane-landing.svg';
import PlaneIconTakeOff from '../../../../images/icons/plane-takeoff.svg';
import TaxiIcon from '../../../../images/icons/car-taxi-front.svg';
import HotelIcon from '../../../../images/icons/bed-double.svg';
import FlightInfo from './itemInfo/flightInfo';
import TransferInfo from './itemInfo/transferInfo';
import HotelInfo from './itemInfo/hotelInfo';
import './styles.css';
import {modifyDateTimeString} from "../../../utils/calculationFunctions";

const ExpandableItem = ({myListData, isLoadingTransfers}) => {
    const navigate = useNavigate();
    const [selectedDetails, setSelectedDetails] = useState({});
    const currentOffer = useSelector((state) => state.offer.currentOffer);
    const selectedOffers = useSelector((state) => state.offer.selectedOffers);


    useEffect(() => {
        const updatedDetails = myListData.reduce((acc, item) => {
            const selectedIndex = selectedOffers[currentOffer] && selectedOffers[currentOffer][item.id] ? selectedOffers[currentOffer][item.id] : 0;
            acc[item.id] = item.info1.details[selectedIndex];
            return acc;
        }, {});
        setSelectedDetails(updatedDetails);
    }, [myListData, selectedOffers, currentOffer]);

    const handleItemClick = (item) => {
        navigate(`/detail/${item.id}`, {state: {myListData}});
    };

    const getIcon = (id) => {
        const icons = [PlaneIconTakeOff, TaxiIcon, HotelIcon, TaxiIcon, PlaneIconLanding];
        return icons[id - 1] || PlaneIconTakeOff;
    };

    const getLabelText = (id) => {
        switch (id) {
            case 1:
                return 'FLIGHT CHECK-IN';
            case 2:
                return 'TRANSFER PICK-UP';
            case 4:
                return 'TRANSFER PICK-UP';
            case 3:
                return 'ACCOMMODATION CHECK-IN';
            case 5:
                return 'FLIGHT CHECK-IN';
            default:
                return 'PICOTRIP';
        }
    };

    const getHighlightedInfo = (id) => {
        switch (id) {
            case 1:
                return true;
            case 2:
            case 4:
                return false;
            case 3:
                return true;
            case 5:
                return true;
            default:
                return true;
        }
    };


    const getLabelTime = (id, myListData) => {
        // Find the item with the specified id from myListData
        const item = myListData.find((data) => data.id === id);

        if (!item) {
            console.error(`Item with id ${id} not found in myListData.`);
            return '';
        }

        // Get the selected index for the current offer and item id
        const selectedIndex = selectedOffers[currentOffer] && selectedOffers[currentOffer][id]
            ? selectedOffers[currentOffer][id]
            : 0;


        let displayTime = '';

        switch (id) {
            case 1: // ARRIVAL
            case 5: // RETURN
                displayTime = item.info1?.details[selectedIndex]?.segments[0]?.departure?.at || 'No departure time available';
                return modifyDateTimeString(displayTime);
            case 2:
            case 4:
                // Assuming 'dateTime' is part of the details for transport items
                displayTime = item.info1?.["selection-box"][`option${selectedIndex + 1}`].time || 'No dateTime available';
                return modifyDateTimeString(displayTime);

            case 3: // ACCOMMODATION
                // Accommodation details check-in time
                console.log("myListData ", myListData)
                displayTime = item.info1?.details[selectedIndex]?.segments[0].checkInTime || 'No dateTime available';
                return displayTime;
            default:
                return '';
        }
    };


    const renderItemContent = (item) => {
        const details = selectedDetails[item.id];

        if (!details) return <span>Loading...</span>;

        switch (item.id) {
            case 1:
            case 5:
                const firstSegment = details.segments[0] || "No flight data available";
                const lastSegment = details.segments[details.segments.length - 1] || "No flight data available";

                return (
                    <FlightInfo
                        flightDetails={{
                            departure: firstSegment.departure || "No departure info",
                            arrival: lastSegment.arrival || "No arrival info",
                            segments: details.segments || "No flight info",
                            totalDuration: details.totalDuration || "No flight info",
                            item: item.id,
                        }}
                    />
                );
            case 2:
            case 4:
                if (isLoadingTransfers) {
                    return <p className="monitor-text">Loading...</p>;
                } else {
                    return <TransferInfo transferDetails={details} isEnabled={getHighlightedInfo(item.id)}/>;
                }
            case 3:
                return <HotelInfo title={item.title} hotelDetails={details}/>;
            default:
                return <span>{item.title}</span>;
        }
    };

    return (
        <div className="timeline-container">
            {myListData.map((item) => (
                <React.Fragment key={item.id}>
                    <div className="timeline-wrapper">
                        <div className="item-label"><p className="label-description">{getLabelText(item.id)}</p>
                            <p className="label-time">{getLabelTime(item.id, myListData)}</p>
                        </div>

                        <div className="timeline-item rounded-button" onClick={() => handleItemClick(item)}>
                            <div className="timeline-icon rounded-left-button">
                                <div className="timeline-icon-inner rounded-left-button">
                                    <img src={getIcon(item.id)} alt=""/>
                                    {/*<p className="label-time">{getLabelTime(item.id, myListData)}</p>*/}
                                </div>
                            </div>
                            <div className="event-container">
                                <div className="event-container-inner">
                                    <div className={`event-info ${getHighlightedInfo(item.id) ? "highlight" : ""}`}>
                                        {renderItemContent(item)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default ExpandableItem;
