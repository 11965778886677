import React, { useState } from "react";
import "../styles.css";
import "../carousel/styles.css";

const CheckoutButton = ({ onClick }) => {
  const [isPressed, setIsPressed] = useState(false);

  // const handleClick = () => {
  //   setIsPressed(true);
  //   setTimeout(() => {
  //     setIsPressed(false);
  //   }, 300);
  //   onClick();
  // };

  return (
    <div
      className={`button-checkout-outer ${isPressed ? "pressed" : ""}`}
    >
      <div className={`button-checkout-inner ${isPressed ? "translate" : ""}`}>
        Checkout
      </div>
    </div>
  );
};

export default CheckoutButton;
