import React from 'react';
import './styles.css';
import {ReactComponent as TreePalm} from '../../../../../images/icons/tree-palm.svg'
import {ReactComponent as Home} from '../../../../../images/icons/home.svg'

const FlightInfo = ({flightDetails}) => {

    const renderFirstIcon = () => {
        if (flightDetails.item === 1) {
            return (
                <>
                    <Home/>

                </>
            );
        } else if (flightDetails.item === 5) {
            return (
                <>
                    <TreePalm/>
                </>
            );
        }
        return null; // Return null if item is neither 1 nor 5
    };

    const renderSecondIcon = () => {
        if (flightDetails.item === 1) {
            return (
                <>
                    <TreePalm/>

                </>
            );
        } else if (flightDetails.item === 5) {
            return (
                <>
                    <Home/>
                </>
            );
        }
        return null; // Return null if item is neither 1 nor 5
    };

    const formatDuration = (duration) => {
        try {
            const matches = duration.match(/PT(\d+H)?(\d+M)?/);
            if (!matches) {
                return duration; // Return the original duration if no matches found
            }
            const hours = matches[1] ? matches[1].slice(0, -1) : '0';
            const minutes = matches[2] ? matches[2].slice(0, -1) : '0';
            return `${hours}h ${minutes}min`;
        } catch (error) {
            console.error('Error parsing duration:', error);
            return duration; // Return the original duration if an error occurs
        }
    };

    return (
        <div>
            {flightDetails && flightDetails.departure && flightDetails.arrival ? (
                <>

                    <p className="monitor-text">{renderFirstIcon()}{flightDetails.departure['iataCode']} &lt;-
                          {formatDuration(flightDetails.totalDuration)} -&gt;
                         {flightDetails.arrival['iataCode']} {renderSecondIcon()}
                    </p>

                </>
            ) : (
                <p className="monitor-text">SELECT FLIGHT</p>
            )}
        </div>
    );
};

export default FlightInfo;
