import React from 'react';
import './styles.css'; // Assuming you will create this CSS file for styling
import {ReactComponent as StarsIcon} from "../../../../../images/icons/star.svg";

const StarsDisplay = ({ numberOfStars }) => {
  return (
    <div className="stars-display">
      {Array.from({ length: numberOfStars }, (_, index) => (
        <StarsIcon key={index} />
      ))}
    </div>
  );
};

export default StarsDisplay;