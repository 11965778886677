import React from "react";
import "./styles.css";

const DatepickerDayButton = ({ day, isActive, onClick, isDisabled }) => {

  return (
      <div className={`datepicker-day ${isActive ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`} onClick={onClick}>
          <div className={`datepicker-day-inner ${isActive ? 'translate' : ''} ${isDisabled ? 'disabled' : ''}`}>
              <div className="day-number">{day}</div>
          </div>

      </div>
  );
};

export default DatepickerDayButton;
