import React from "react";
import "./styles.css";

const LuxOptionButton = ({ isPressed, onClick }) => {
    return (
        <div
            className={`button-lux-outer ${isPressed ? "pressed" : ""}`}
            onClick={onClick}
        >
            <div className={`button-lux-inner ${isPressed ? "translate" : ""}`}>
                Lux
            </div>
        </div>
    );
};

export default LuxOptionButton;
