import React, { useState, useEffect } from "react";
import "./styles.css";
import DatepickerDayButton from "../tripOrganisation/buttons/keyboardButton";
import DatepickerDoneButton from "../tripOrganisation/buttons/datepickerDoneButton";
import LeftMonthButton from "../tripOrganisation/buttons/leftMonthButton";
import MonthButton from "../tripOrganisation/buttons/monthButton";
import RightMonthButton from "../tripOrganisation/buttons/rightMonthButton";
import YearButton from "../tripOrganisation/buttons/yearButton";

const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const CustomCalendar = ({ isOpen, onClose, onRangeSelect }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [activeStartDate, setActiveStartDate] = useState(null);
    const [activeEndDate, setActiveEndDate] = useState(null);
    const [error, setError] = useState(""); // Add error state

    const handleClick = () => {
        onClose();
    };

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const isDaySelectable = (day) => {
        const today = new Date();
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
        const next7Days = new Date();
        next7Days.setDate(today.getDate() + 7);

        // A selectable day is one that is not within the next 7 days
        return date > next7Days;
    };

    const hasSelectableDaysInCurrentMonth = () => {
        const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());

        for (let day = 1; day <= daysInMonth; day++) {
            if (isDaySelectable(day)) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        if (!hasSelectableDaysInCurrentMonth()) {
            handleNextMonth(); // Automatically show next month if no selectable days in the current month
        }
    }, []); // This effect runs only on the first load

    useEffect(() => {
        if (activeStartDate && activeEndDate) {
            onRangeSelect({ departureDate: activeStartDate, returnDate: activeEndDate });
        }
    }, [activeStartDate, activeEndDate, onRangeSelect]);

    const getNumberOfRows = () => {
        const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
        const totalDays = firstDayOfMonth + daysInMonth;
        let calculatedRows;
        calculatedRows = Math.ceil(totalDays / 7);
        return calculatedRows;
    };

    const handlePreviousMonth = () => {
        let newMonth = currentDate.getMonth() - 1;
        let newYear = currentDate.getFullYear();
        if (newMonth < 0) {
            newMonth = 11;
            newYear -= 1;
        }
        setCurrentDate(new Date(newYear, newMonth, 1));
    };

    const handleNextMonth = () => {
        let newMonth = currentDate.getMonth() + 1;
        let newYear = currentDate.getFullYear();
        if (newMonth > 11) {
            newMonth = 0;
            newYear += 1;
        }
        setCurrentDate(new Date(newYear, newMonth, 1));
    };

    const isDayActive = (day) => {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
        if (activeStartDate && activeEndDate) {
            return date >= activeStartDate && date <= activeEndDate;
        } else if (activeStartDate) {
            return date.getTime() === activeStartDate.getTime();
        }
        return false;
    };

    const handleDayClick = (day) => {
        setError(""); // Reset error on click
        const selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);

        if (activeStartDate && activeEndDate) {
            setActiveStartDate(selectedDate);
            setActiveEndDate(null);
        } else if (activeStartDate && !activeEndDate) {
            if (selectedDate < activeStartDate) {
                setActiveEndDate(activeStartDate);
                setActiveStartDate(selectedDate);
            } else {
                const diffInTime = selectedDate.getTime() - activeStartDate.getTime();
                const diffInDays = diffInTime / (1000 * 3600 * 24);

                if (diffInDays < 2) {
                    setError("Please select at least 2 days range for your trip!");
                } else {
                    setActiveEndDate(selectedDate);
                }
            }
        } else {
            setActiveStartDate(selectedDate);
        }
    };

    const renderDays = () => {
        const daysInMonth = getDaysInMonth(currentDate.getFullYear(), currentDate.getMonth());
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
        const days = [];

        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(<div key={`empty-${i}`} className="datepicker-day-empty" />);
        }

        for (let i = 1; i <= daysInMonth; i++) {
            const isDisabled = !isDaySelectable(i); // Disable days that are not selectable
            days.push(
                <DatepickerDayButton
                    key={i}
                    day={i}
                    isActive={isDayActive(i)}
                    onClick={() => handleDayClick(i)}
                    isDisabled={isDisabled}
                />
            );
        }

        if (getNumberOfRows() === 5) {
            for (let i = 0; i < 7; i++) {
                days.push(<div key={`extra-${i}`} className="datepicker-day-empty" />);
            }
        }

        return days;
    };

    return (
        <div className={`custom-calendar ${isOpen ? 'open' : ''}`}>
            <div className="calendar-header">
                <div className="calendar-header-inner">
                    <div className="month-container bottom-shadow">
                        <LeftMonthButton onClick={handlePreviousMonth} />
                        <MonthButton>{months[currentDate.getMonth()]}</MonthButton>
                        <RightMonthButton onClick={handleNextMonth}></RightMonthButton>
                    </div>
                    <YearButton>{currentDate.getFullYear()}</YearButton>
                </div>
            </div>
            {error && <div className="error-detail">{error}</div>} {/* Display error if exists */}
            <div className="datepicker-day-names">
                {dayNames.map((dayName, index) => (
                    <div key={index} className="datepicker-day-name">
                        {dayName}
                    </div>
                ))}
            </div>
            <div className={`calendar-days ${getNumberOfRows() === 5 ? 'tight-rows' : ''}`}>
                {renderDays()}
            </div>
            <div>
                <DatepickerDoneButton onClick={handleClick} />
            </div>
        </div>
    );
};

export default CustomCalendar;
