export const SET_TRANSFER_FETCHED = 'SET_TRANSFER_FETCHED';
export const RESET_TRANSFER_FETCHED = 'RESET_TRANSFER_FETCHED';

export const SET_TRANSFER_DATA = 'SET_TRANSFER_DATA';
export const setTransferFetched = (fetched) => ({
    type: SET_TRANSFER_FETCHED,
    payload: fetched
});

export const resetTransferFetched = () => ({
    type: RESET_TRANSFER_FETCHED
});

// Action creator for setting transfer data
export const setTransferData = (data) => {
    return {
        type: SET_TRANSFER_DATA,
        payload: data,
    };
};