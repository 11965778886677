import DestinationButton from "../buttons/cityButton";
import React from "react";
import {selectCurrentOffer} from "../../../store/actions/offerActions";
import {useDispatch, useSelector} from "react-redux";

const leftButton = 'city-left';
const middleButton = 'city-middle';
const rightButton = 'city-right';
const singleButton = 'one-city';
const firstOfTwoButtons = 'first-of-two-cities';
const secondOfTwoButtons = 'second-of-two-cities';


const getCityName = (offers, index) => {
    let cityName;
    try {
        cityName = offers[index].offers.city || null;
    } catch (error) {
        console.error("Error fetching data:", error);
    }
    return cityName;
}


const RenderDestinationButtons = (offers, numberOfOffers) => {
    const dispatch = useDispatch(); // Use dispatch to trigger Redux actions
    const selectedOffers = useSelector((state) => state.offer.selectedOffers); // Get state data
    const currentOfferId = useSelector((state) => state.offer.currentOffer); // Get current offer

    const handleLeft = () => {
        const newIndex = 0;
        dispatch(selectCurrentOffer(newIndex));
    };

    const handleMiddle = () => {
        const newIndex = 1;
        dispatch(selectCurrentOffer(newIndex));
    };

    const handleRight = () => {
        const newIndex = 2;
        dispatch(selectCurrentOffer(newIndex));
    };
    const isButtonSelected = (index, currentOfferId) => {
        return index === currentOfferId;
    }

    switch (numberOfOffers) {
        case 1:
            return (
                <div className="destination-buttons">
                    <DestinationButton onClick={handleLeft} cityName={getCityName(offers,0)}
                                       position={singleButton}
                                       isSelected={isButtonSelected(0, currentOfferId)}></DestinationButton>
                </div>
            )
        case 2:
            return (<div className="destination-buttons">
                <DestinationButton onClick={handleLeft} cityName={getCityName(offers,0)}
                                   position={firstOfTwoButtons}
                                   isSelected={isButtonSelected(0, currentOfferId)}></DestinationButton>
                <DestinationButton onClick={handleMiddle} cityName={getCityName(offers,1)}
                                   position={secondOfTwoButtons}
                                   isSelected={isButtonSelected(1, currentOfferId)}></DestinationButton>
            </div>);

        case 3:
            return (<div className="destination-buttons">
                <DestinationButton onClick={handleLeft} cityName={getCityName(offers,0)}
                                   position={leftButton}
                                   isSelected={isButtonSelected(0, currentOfferId)}></DestinationButton>
                <DestinationButton onClick={handleMiddle} cityName={getCityName(offers,1)}
                                   position={middleButton}
                                   isSelected={isButtonSelected(1, currentOfferId)}></DestinationButton>
                <DestinationButton onClick={handleRight} cityName={getCityName(offers,2)}
                                   position={rightButton}
                                   isSelected={isButtonSelected(2, currentOfferId)}></DestinationButton>
            </div>);
        default:
            return null;
    }
}

export default RenderDestinationButtons;