import React, { useState } from "react";
import "./styles.css";
import {ReactComponent as  CalendarIcon} from "../../../images/icons/calendar.svg";

const CalendarButton = ({  onClick }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleClick = () => {
    setIsPressed(true);
    setTimeout(() => {
      setIsPressed(false);
    }, 300);
    onClick();
  };

  return (
    <div
      className={`button-calendar-outer rounded-left-button ${isPressed ? "pressed" : ""}`}
      onClick={handleClick}
    >
      <div className={`button-calendar-inner rounded-left-button ${isPressed ? "translate" : ""}`}>
        <CalendarIcon style={{ margin: "auto" }} />
      </div>
    </div>
  );
};

export default CalendarButton;
