// actions/offerActions.js

export const SELECT_CURRENT_OFFER = 'SELECT_CURRENT_OFFER';
export const UPDATE_OFFER_SELECTION = 'UPDATE_OFFER_SELECTION';
export const RESET_OFFERS = 'RESET_OFFERS';
export const SET_IMAGE_INDEX = 'SET_IMAGE_INDEX'; // Add a new action type
export const SET_HOTEL_ID = 'SET_HOTEL_ID'; // New action for hotel ID

export const selectCurrentOffer = (offerId) => ({
    type: SELECT_CURRENT_OFFER,
    payload: offerId,
});

export const updateOfferSelection = (offerId, itemId, optionId) => ({
    type: UPDATE_OFFER_SELECTION,
    payload: {offerId, itemId, optionId},
});

export const resetOffers = () => ({
    type: RESET_OFFERS,
});

export const setHotelId = (offerId, hotelId) => ({
    type: SET_HOTEL_ID,
    payload: { offerId, hotelId },
});

export const setImageIndex = (offerId, hotelId, index) => ({
    type: SET_IMAGE_INDEX,
    payload: { offerId, hotelId, index },
});