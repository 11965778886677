import { SELECT_CURRENT_OFFER, UPDATE_OFFER_SELECTION, RESET_OFFERS, SET_IMAGE_INDEX, SET_HOTEL_ID } from "../actions/offerActions";

const initialState = {
  selectedOffers: {},
  currentOffer: 1,  // Assuming the first offer is selected by default
  imageIndices: {}, // Store image indices for each offer and hotel
  hotelIds: {},     // Store hotel IDs for each offer
};

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_CURRENT_OFFER:
      return {
        ...state,
        currentOffer: action.payload,
      };

    case UPDATE_OFFER_SELECTION:
      const { offerId, itemId, optionId } = action.payload;
      const updatedSelectedOffers = {
        ...state.selectedOffers,
        [offerId]: {
          ...state.selectedOffers[offerId],
          [itemId]: optionId,
        },
      };
      return {
        ...state,
        selectedOffers: updatedSelectedOffers,
      };

    case RESET_OFFERS:
      return {
        ...state,
        selectedOffers: {},
        currentOffer: 0,
        imageIndices: {}, // Reset on offer reset
        hotelIds: {},     // Reset hotel IDs
      };

    case SET_IMAGE_INDEX: {
      const { offerId, hotelId, index } = action.payload;
      return {
        ...state,
        imageIndices: {
          ...state.imageIndices,
          [offerId]: {
            ...(state.imageIndices[offerId] || {}),
            [hotelId]: index,
          },
        },
      };
    }

    case SET_HOTEL_ID: {
      const { offerId, hotelId } = action.payload;
      return {
        ...state,
        hotelIds: {
          ...state.hotelIds,
          [offerId]: hotelId,
        },
      };
    }

    default:
      return state;
  }
};

export default offerReducer;
