import React from "react";
import "./styles.css";

const MonthButton = ({children}) => {
  return (
    <div className="button-month-outer">
      <div className="button-month-inner">
          {children}
      </div>
    </div>
  );
};

export default MonthButton;
