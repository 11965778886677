// src/store/reducers/transferReducer.js

import {SET_TRANSFER_FETCHED, RESET_TRANSFER_FETCHED, SET_TRANSFER_DATA} from '../actions/transferActions';

const initialState = {
    transferFetched: false,
};

const transferReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSFER_FETCHED:
            return {
                ...state,
                transferFetched: action.payload,
            };
        case RESET_TRANSFER_FETCHED:
            return {
                ...state,
                transferFetched: false,
            };
        case SET_TRANSFER_DATA:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};

export default transferReducer;
