import React from "react";
import "./styles.css";

const StandardOptionButton = ({ isPressed, onClick }) => {
    return (
        <div
            className={`button-standard-outer ${isPressed ? "pressed" : ""}`}
            onClick={onClick}
        >
            <div className={`button-standard-inner ${isPressed ? "translate" : ""}`}>
                Standard
            </div>
        </div>
    );
};

export default StandardOptionButton;
