// src/utils/calculationFunctions.js

/**
 * Extracts numbers from a string and converts it to a floating-point number.
 * @param {string} str - The string to process.
 * @return {number} - The extracted floating-point number.
 */
function extractNumberFromString(str) {
    const cleanedString = str.replace(/[^0-9.]/g, '');
    return parseFloat(cleanedString);
}

/**
 * Extracts currency symbols from a string.
 * @param {string} str - The string containing the currency.
 * @return {string} - The extracted currency symbols.
 */
function extractCurrency(str) {
    return str.replace(/[0-9.,\s]/g, '').trim();
}

/**
 * Formats a number to two decimal places.
 * @param {number} num - The number to format.
 * @return {number} - The number formatted to two decimal places.
 */
function formatToTwoDecimals(num) {
    return parseFloat(num.toFixed(2));
}

const formatCurrency = (currency) => {
    return currency === 'EUR' ? `€` : `${currency}`;
};

/**
 * Calculates the number of vacation days between two dates.
 * @param {string} departureDate - The departure date.
 * @param {string} returnDate - The return date.
 * @return {number} - The number of vacation days.
 */
const calculateVacationDays = (departureDate, returnDate) => {
    const startDate = new Date(departureDate);
    const endDate = new Date(returnDate);
    const timeDifference = endDate - startDate;
    return timeDifference / (1000 * 60 * 60 * 24); // Convert time difference from milliseconds to days
};

/**
 * Reformats a dateTime string to a human-readable format.
 * @param {string} dateTimeString - The dateTime string to modify.
 * @return {string} - The reformatted dateTime string.
 */
const modifyDateTimeString = (dateTimeString) => {
    const dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})(?:T(\d{2}):(\d{2}):(\d{2}))?$/;
    const match = dateTimeString.match(dateTimeRegex);
    if (!match) {
        console.error('Invalid date-time format:', dateTimeString);
        return dateTimeString;
    }

    const [, year, month, day, hour, minute] = match;

    if (hour && minute) {
        return `${year}-${month}-${day} ${hour}:${minute}`;
    }

    return `${year}-${month}-${day}`;
};

/**
 * Calculates the duration in minutes between two dateTime strings.
 * @param {string} startDateTime - The starting dateTime.
 * @param {string} endDateTime - The ending dateTime.
 * @return {number} - The duration in minutes.
 */
const calculateDurationInMinutes = (startDateTime, endDateTime) => {
    const start = new Date(startDateTime);
    const end = new Date(endDateTime);
    const differenceInMilliseconds = end - start;

    // Convert milliseconds to minutes
    const durationInMinutes = differenceInMilliseconds / (1000 * 60);

    return `${Math.round(durationInMinutes)} min`; // Round to nearest minute
};


const getGrandTotal = (selectedOffers, currentOfferId, mockedOffers) => {
    // Calculating Grand total
    let travelOfferTotal = 0;
    let currency = '';
    for (let itemId = 1; itemId <= 5; itemId++) {
        let selectedOptionForItem = selectedOffers[currentOfferId]?.[itemId];

        if (!selectedOptionForItem) {
            selectedOptionForItem = 0;
        }
        let option = [`option${selectedOptionForItem + 1}`];
        let itemPrice = 0;
        try {itemPrice = mockedOffers[currentOfferId].offers[itemId - 1].info1["selection-box"][`option${selectedOptionForItem + 1}`].price;}
        catch (e) {
            console.log("Looks like price is not available for item ", itemId)
        }

        let itemPriceNumber = 0;
        if (itemPrice && itemPrice !== 0) {
            itemPriceNumber = extractNumberFromString(itemPrice);
            currency = extractCurrency(itemPrice);
        }
        travelOfferTotal = travelOfferTotal + itemPriceNumber;

    }
    travelOfferTotal = formatToTwoDecimals(travelOfferTotal);
    travelOfferTotal = travelOfferTotal + currency;
    return travelOfferTotal;
};

// Export all utility functions
export {
    extractNumberFromString,
    extractCurrency,
    formatToTwoDecimals,
    formatCurrency,
    calculateVacationDays,
    modifyDateTimeString,
    calculateDurationInMinutes,
    getGrandTotal
};
