import React, {useEffect, useState} from "react";
import PostRequest from "../../../api/postRequest";
import LoadingSpinner from "../../../utils/loadingSpinner/loadingSpinner";
import '../../../../commonStyles.css';

import Navigation from "../../../footer/navigation";
import {ReactComponent as BudgetImage} from "../../../../images/budget/budget.svg";
import {ReactComponent as StandardImage} from "../../../../images/budget/standard.svg";
import {ReactComponent as LuxImage} from "../../../../images/budget/lux.svg";

import BudgetOptionButton from "../../buttons/budgetOptionButton";
import StandardOptionButton from "../../buttons/standardOptionButton";
import LuxOptionButton from "../../buttons/luxOptionButton";

function BudgetSmartphoneStep({nextStep, prevStep, selectedCities}) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('Budget'); // Set initial state to 'Budget'


    const handleNextStep = () => {
        sendSelectedCategory(selectedCategory);
        nextStep();
    };

    const sendSelectedCategory = (selectedCategory) => {
        let path = 'api/set_budget/';
        const data = PostRequest(selectedCategory, path, 'json')

        if (data === "budget set!") {
            console.log("Budget set successfully")
        }
    }

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const categoryImages = {
        Budget: <BudgetImage/>,
        Standard: <StandardImage/>,
        Lux: <LuxImage/>
    };

    return (
        <div className="form-inline-wrapper">
            <h2 className='page-title'>Budget Selection</h2>
            {isLoading ? (
                <LoadingSpinner/>
            ) : (
                <>
                    <div className="budget-container">
                        <div className="budget-container-inner">
                            <div className="budget-monitor">
                                {selectedCategory && categoryImages[selectedCategory]}
                            </div>
                            <div className="budget-buttons">
                                <BudgetOptionButton
                                    isPressed={selectedCategory === 'Budget'}
                                    onClick={() => handleCategoryClick('Budget')}
                                />
                                <StandardOptionButton
                                    isPressed={selectedCategory === 'Standard'}
                                    onClick={() => handleCategoryClick('Standard')}
                                />
                                <LuxOptionButton
                                    isPressed={selectedCategory === 'Lux'}
                                    onClick={() => handleCategoryClick('Lux')}
                                />
                            </div>
                        </div>
                    </div>
                    <Navigation onPreviousClick={prevStep} onNextClick={handleNextStep}/>
                </>
            )}
        </div>
    );
}

export default BudgetSmartphoneStep;
