import React from "react";
import "./styles.css";

import {ReactComponent as  AdultsIcon} from '../../../images/icons/user-2.svg';

const AdultsButton = () => {
  return (
    <div className="button-adults-outer">
      <div className="button-adults-inner">
          <AdultsIcon/>
      </div>
    </div>
  );
};

export default AdultsButton;
