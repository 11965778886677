import React from "react";
import "./styles.css";

import {ReactComponent as  ChildrenIcon} from '../../../images/icons/baby.svg';

const ChildrenButton = () => {
  return (
    <div className="button-adults-outer">
      <div className="button-adults-inner">
          <ChildrenIcon/>
      </div>
    </div>
  );
};

export default ChildrenButton;
