import React, {useState} from "react";
import "./styles.css";

const DatepickerDoneButton = ({onClick}) => {
    const [isPressed, setIsPressed] = useState(false);

    const handleClick = () => {
        setIsPressed(true);
        setTimeout(() => {
            setIsPressed(false);
        }, 300);
        onClick();
    };

    return (
        <div
            className={`button-done-outer rounded-button ${isPressed ? "pressed" : ""}`}
            onClick={handleClick}
        >
            <div className={`button-done-inner rounded-button ${isPressed ? "translate" : ""}`}>
                Done
            </div>
        </div>
    );
};

export default DatepickerDoneButton;
