// ImagesSlider.js
import './styles.css';
import React from 'react';

const ImagesCitiesSlider = ({ preloadedImages, currentIndex, altAttribute, cityName, onClick }) => {
    return (
        <div className="destination-container-outer" onClick={onClick}> {/* Attach onClick here */}
            <div className="images-container">
                <div className="images-container-inner">
                    {preloadedImages[currentIndex] ? (
                        <img
                            src={preloadedImages[currentIndex]}
                            alt={altAttribute}
                            className="image"
                        />
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
            </div>
            <h2 className="destination-title">{cityName}</h2>
        </div>
    );
};

export default ImagesCitiesSlider;
