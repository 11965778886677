import {formatCurrency} from "./calculationFunctions";
import Cookies from "js-cookie";


/**
 * Function to safely extract hotel details from the hotelInfo object.
 * @param {Object} hotelInfo - real data object containing hotels data.
 * @param {Object} currentOfferDetailsHotel - object where extracted data is stored
 * @param {number} optionIndex - The index of the specific hotel offer, can be 1,2 and 3
 *
 * @returns {Object|null} - The extracted hotel details or null if not found.
 */
function processAccommodationInfo(hotelInfo, optionIndex, currentOfferDetailsHotel) {
    try {
        let totalPrice = hotelInfo.dynamic_data.payment_options.payment_types[0].amount;
        let currency = hotelInfo.dynamic_data.payment_options.payment_types[0].currency_code;
        let hotelName = hotelInfo.static_data.name;
        let checkInTime = hotelInfo.static_data.check_in_time;
        let checkOutTime = hotelInfo.static_data.check_out_time;
        let starRating = hotelInfo.static_data.star_rating;
        let amenityGroups = hotelInfo.static_data.amenity_groups;
        let hotelImages = hotelInfo.static_data.images;
        let roomInfo = hotelInfo.dynamic_data.room_data_trans.main_name;

        // Ensure the structure for optionIndex exists in selection-box and details
        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`] = currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`] || {};
        currentOfferDetailsHotel.info1.details[optionIndex - 1] = currentOfferDetailsHotel.info1.details[optionIndex - 1] || {segments: [{}]};

        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`].price = `${totalPrice}${formatCurrency(currency)}`;
        currentOfferDetailsHotel.info1["selection-box"][`option${optionIndex}`].name = hotelName;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].name = hotelName;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].checkInTime = checkInTime;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].checkOutTime = checkOutTime;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].stars = starRating;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].amenity_groups = amenityGroups;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].images = hotelImages;
        currentOfferDetailsHotel.info1.details[optionIndex - 1].segments[0].roomInfo = roomInfo;

    } catch (error) {
        console.log(`No hotel ${optionIndex} info`);
        console.error(error);
    }
    return currentOfferDetailsHotel;
}

// Function to process flight to destination data
const processFlightToDestination = (offerData, flightDetails) => {
    if (!offerData || !Array.isArray(offerData)) {
        console.log("No real offer details available for departure");
        return;
    }

    offerData.forEach((offer, index) => {
        const realOfferFlightInfo = offer[0][0];
        const option = `option${index + 1}`;

        // Updating the flight details
        flightDetails.info1["selection-box"][option].price =
            `${realOfferFlightInfo["price"]["grandTotal"]}${formatCurrency(realOfferFlightInfo["price"]["currency"])}`;
        flightDetails.info1["selection-box"][option].time =
            realOfferFlightInfo.itineraries[0].segments[0].departure.at;
        flightDetails.info1.details[index].totalDuration = realOfferFlightInfo.itineraries[0].duration;
        flightDetails.info1.details[index].segments = realOfferFlightInfo.itineraries[0].segments;
    });

};


// Function to process return flight details
const processFlightFromDestination = (realOfferData, flightDetails) => {
    if (!realOfferData || !Array.isArray(realOfferData)) {
        console.log("No real offer details available for return");
        return;
    }

    realOfferData.forEach((realOffer, index) => {
        const option = `option${index + 1}`;
        const detailIndex = index;

        if (realOffer && realOffer[0][0]["oneWay"]) {
            const oneWayOffer = realOffer[0][1];
            flightDetails.info1["selection-box"][option].price =
                `${oneWayOffer["price"]["grandTotal"]}${formatCurrency(oneWayOffer["price"]["currency"])}`;
            flightDetails.info1["selection-box"][option].time =
                oneWayOffer.itineraries[0].segments[0].departure.at;
            flightDetails.info1.details[detailIndex].totalDuration = oneWayOffer.itineraries[0].duration;
            flightDetails.info1.details[detailIndex].segments = oneWayOffer.itineraries[0].segments;
        } else if (realOffer) {
            const roundTripOffer = realOffer[0][0];
            flightDetails.info1["selection-box"][option].price =
                `${roundTripOffer["price"]["grandTotal"]}${formatCurrency(roundTripOffer["price"]["currency"])}`;
            flightDetails.info1["selection-box"][option].time =
                roundTripOffer.itineraries[1].segments[0].departure.at;
            flightDetails.info1.details[detailIndex].totalDuration = roundTripOffer.itineraries[1].duration;
            flightDetails.info1.details[detailIndex].segments = roundTripOffer.itineraries[1].segments;
        }
    });
};

const processTransfer = (transferData, transferDetails, index, transferTypeKey, optionKey) => {
    if (!transferData || !transferData.message[index] || transferData.message === "No transfers found") {
        transferDetails.info1["selection-box"][optionKey] = { price: 0, time: 0 };
        transferDetails.info1.details[0] = {
            segments: [{
                start: { dateTime: 0, locationCode: 0 },
                end: { dateTime: 0 },
                transferType: 0
            }]
        };
    } else {
        const transfer = transferData.message[index][transferTypeKey];
        transferDetails.info1["selection-box"][optionKey] = {
            price: `${transfer.converted.monetaryAmount}${formatCurrency(transfer.converted.currencyCode)}`,
            time: transfer.start.dateTime
        };
        transferDetails.info1.details[0] = {
            segments: [{
                start: {
                    dateTime: transfer.start.dateTime,
                    locationCode: transfer.start.locationCode
                },
                end: { dateTime: transfer.end.dateTime },
                transferType: transfer.transferType
            }]
        };
    }
};

// Usage for both accommodations
const processTransferToAccommodation = (transferData, transferDetails, index) => {
    processTransfer(transferData, transferDetails, index, 'transfer_to_hotel', 'option1');
};

const processTransferFromAccommodation = (transferData, transferDetails, index) => {
    processTransfer(transferData, transferDetails, index, 'transfer_to_origin', 'option1');
};


/**
 * Function to get cookies set after first step in order to use data on offers page
 * **/
const getRequestParamsFromCookies = () => {
    // Retrieve the cookie by its name, which stores JSON string
    const params = Cookies.get('request_params');
    // Check if the params exist and are not undefined
    if (params) {
        try {
            // Parse the JSON string back into an object
            const paramsObj = JSON.parse(params);
            // Return the departureDate or any other required value
            return paramsObj
        } catch (error) {
            console.error("Error parsing JSON from cookies", error);
            return null;  // Return null or default value in case of error
        }
    }
    return null; // Return null if the cookie doesn't exist
};


function countFirstLevelChildren(json) {
    if (typeof json === 'object' && json !== null) {
        return Object.keys(json).length; // Returns the number of direct children (first level)
    }
    return 0; // Return 0 if it's not a valid object
}

const getNumberOfValidOffers = (offers) => {
    let numberOfValidOffers = 0;

    // Loop through indexes 1, 2, and 3
    for (let index = 0; index <= 2; index++) {
        const details = offers[index] || null;
         console.log("inside the function ", details )
        if (!details || details.offers.data === "no hotels found on the location") {
            console.log(`Invalid or no offer for city at index ${index}`);
        } else {
            numberOfValidOffers += 1;
        }
    }

    return numberOfValidOffers;
};

const isOfferValid = (currentRealOfferDetails) => {
    if (!currentRealOfferDetails || !currentRealOfferDetails.offers || !currentRealOfferDetails.offers.data) {
        console.log("offer is null or missing");
        return false;
    }
    let offerData;
    console.log("initial data ", currentRealOfferDetails)
    try {
        offerData = currentRealOfferDetails.offers.data;

        // Check if all offers have length === 0
        const allOffersEmpty = offerData.every(offer => offer[1].length === 0);

        if (allOffersEmpty) {
            return false;
        }
    } catch (error) {
        console.log("errro while checking data validity ", error);
    }


    // Additional check for "no hotels found on the location"
    if (offerData === "no hotels found on the location") {
        return false;
    }

    return true;
};

export {
    processAccommodationInfo,
    getRequestParamsFromCookies,
    getNumberOfValidOffers,
    processFlightFromDestination,
    processFlightToDestination,
    countFirstLevelChildren,
    processTransferToAccommodation,
    processTransferFromAccommodation,
    isOfferValid
};