import React, { useState } from "react";
import "./styles.css";

const MinusButton = ({ onClick }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleClick = () => {
    setIsPressed(true);
    setTimeout(() => {
      setIsPressed(false);
    }, 300);
    onClick();
  };

  return (
    <div
      className={`button-minus-outer rounded-left-button ${isPressed ? "pressed" : ""}`}
      onClick={handleClick}
    >
      <div className={`button-minus-inner rounded-left-button ${isPressed ? "translate" : ""}`}>
        -
      </div>
    </div>
  );
};

export default MinusButton;
