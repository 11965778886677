import React, {useState} from "react";
import "./styles.css";

const SelectDetailButton = ({ onClick }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleClick = () => {
    setIsPressed(true);
    setTimeout(() => {
      onClick(); // Switch to the next page after the animation
      setIsPressed(false); // Reset the pressed state
    }, 300); // 300 ms delay to match the animation duration
  };

  return (
    <div
      className={`button-detail-outer rounded-right-button ${isPressed ? "pressed" : ""}`}
      onClick={handleClick}
    >
      <div className={`button-detail-inner rounded-right-button ${isPressed ? "translate" : ""}`}>
        Select
      </div>
    </div>
  );
};

export default SelectDetailButton;