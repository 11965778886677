import React from 'react';

import './styles.css';
import SelectDetailButton from "../tripOrganisation/buttons/selectDetailButton";
import LeftMonthButton from "../tripOrganisation/buttons/leftMonthButton";

const DetailsNavigation = ({
  onClick
}) => {
  return (
    <div className="navigation-wrapper">
        <div className="navigation">
          {
            <LeftMonthButton onClick={onClick} />
          }
          {
            <SelectDetailButton onClick={onClick} />
          }
        </div>
    </div>
  );
};

export default DetailsNavigation;
